<template>
  <div>
    <i-table stripe  :data="list" :columns="columns" ></i-table>
  </div>
</template>

<script>
import { getInvoiceList } from '@/api/scp/invoice'

import { toMoney } from '@/utils/wnumb_own'
import { formatInvoiceType, formatInvoiceStatus } from '@/utils/tagStatus'

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      list: [],
      columns: [
        {
          title: '开票金额(含税)',
          render: (h, data) => {
            return h('span', {
              style: {
                color: '#19be6b'
              }
            }, toMoney(data.row.amount))
          }
        },
        { title: '发票号码', key: 'code' },
        { title: '开票日期', key: 'date' },
        { title: '费用类型', key: 'feeTypeName' },
        {
          title: '发票类型',
          render: (h, data) => {
            return formatInvoiceType(h, data.row.type, data.row.typeName)
          }
        },
        {
          title: '状态',
          render: (h, data) => {
            return formatInvoiceStatus(h, data.row.status, data.row.statusName)
          }
        }
      ]
    }
  },
  methods: {
    getList () {
      getInvoiceList({ relateId: this.id }).then(res => {
        this.list = res
      })
    }
  },
  created () {
    this.getList()
  }

}
</script>
