<template>
  <Card v-if="value&&value.length>0">
    <p slot="title">付款方信息变化</p>
    <Row
      type="flex"
      align="middle"
      justify="center"
      :gutter="18"
    >
      <i-col span="11">
        <Card v-if="firstParty">
          <Row>
            <i-col span="12">名称： {{firstParty.from.name}}</i-col>
            <i-col span="12">开户行： {{firstParty.from.bank}}</i-col>
            <i-col span="12">账号： {{firstParty.from.bankAccount}}</i-col>
            <i-col span="12">地址： {{firstParty.from.address}}</i-col>
            <i-col span="12">联系电话： {{firstParty.from.tel}}</i-col>
            <i-col span="12">发票类型：<Tag color="green"> {{firstParty.from.invoiceTypeName}}</Tag>
            </i-col>

          </Row>

        </Card>
      </i-col>

      <i-col span="2" class="text-center">
          <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
      </i-col>

      <i-col span="11">
        <Card v-if="firstParty">
          <Row>
            <i-col span="12">名称： {{firstParty.to.name}}</i-col>
            <i-col span="12">开户行： {{firstParty.to.bank}}</i-col>
            <i-col span="12">账号： {{firstParty.to.bankAccount}}</i-col>
            <i-col span="12">地址： {{firstParty.to.address}}</i-col>
            <i-col span="12">联系电话： {{firstParty.to.tel}}</i-col>
            <i-col span="12">发票类型：<Tag color="green"> {{firstParty.to.invoiceTypeName}}</Tag>
            </i-col>

          </Row>

        </Card>

      </i-col>
    </Row>
  </Card>
</template>
<script>

export default {
  props: {
    value: Array
  },
  computed: {
    firstParty: function () {
      return this.value.find(x => x.field === 'customerInfoBean')
    }
  }

}
</script>
