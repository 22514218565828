<template>
    <Row>
      <i-col span="24">
        <component class="m-t-10"
          v-for="(item,i) in detail"
          :is="item.key"
          :value="item.value"
          :key="i"
        ></component>
      </i-col>
    </Row>

</template>

<script>

import contract from './Basic'
import payer from './Payer'
import price from './Price'
import payments from './Payments'
import annex from './Annex'
export default {
  components: {
    contract, payer, price, payments, annex
  },
  props: {
    detail: Array
  },
  data () {
    return {
      // changeFields: []
    }
  },
  created () {
    // this.changeFields = getChangeFields(this.detail)
  }

}
</script>
