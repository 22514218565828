<template>
        <Card v-if="value.length>0"  :bordered="false" dis-hover>
            <p slot="title">附件信息</p>
            <Row type="flex" align="middle" justify="center" :gutter="9">
                <i-col span="11">
                    <i-table stripe  :data="fromArray" :columns="fileColumns"></i-table>
                </i-col>
                <i-col span="2" class="text-center">
                    <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
                </i-col>
                <i-col span="11">
                    <i-table stripe  :data="toArray" :columns="fileColumns"></i-table>
                </i-col>
            </Row>
            <Modal title="查看大图" v-model="showPreview" width="800">
                <img :src="formatImgUrl('fileStementModalImg')" id="fileStementModalImg" v-if="showPreview" style="width: 100%">
            </Modal>
        </Card>
</template>

<script>

import { downloadFileRequest, downloadImgRequest } from '@/utils/download'
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    value: Array
  },
  data () {
    return {
      statementBean: {},
      changeAmountBean: null,
      changePayDiscountBean: null,
      changePaymentsBean: null,
      changeFilesBean: null,
      fileColumns: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 38, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '50px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '50px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '操作',
          render: (h, data) => {
            let preview
            if (data.row.mimeType.startsWith('image')) {
              preview = h('a',
                {
                  style: {
                    'margin-left': '5px'
                  },
                  on: {
                    click: () => {
                      this.showFileId = data.row.id
                      this.showPreview = true
                    }
                  }
                }, '预览')
            }
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.handleDownload(data.row)
                  }
                }
              }, '下载'), preview
            ])
          }
        }
      ],
      showPreview: false,
      showFileId: ''
    }
  },
  methods: {
    formatMoney (money) {
      return toMoney(money)
    },
    formatImgUrl (imgElementId) {
      const imgUrl = process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + this.showFileId
      return downloadImgRequest(imgUrl, imgElementId)
    },
    handleDownload (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    }
  },
  computed: {
    fromArray: function () {
      let tmp
      this.value.forEach(item => {
        tmp = item.from.map((content, index) => {
          return content
        })
      })
      return tmp
    },
    toArray: function () {
      let tmp
      this.value.forEach(item => {
        tmp = item.to.map((content, index) => {
          return content
        })
      })
      return tmp
    }

  }
}
</script>
