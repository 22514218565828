<template>
  <Modal v-model="modalShow" width="1000" footer-hide>
    <Tabs v-model="chkOrderId" size="small" class="text-white">
      <TabPane
        v-for="order in orders"
        :key="order.id"
        :label="order.code"
        :name="order.id+''"
      >
      </TabPane>
    </Tabs>

    <Tabs v-model="currentTabPane" size="small" class="text-white">
      <TabPane
        v-for="category in categories"
        :key="category.categoryId"
        :label="category.name"
        :name="category.category"
      >
      </TabPane>
    </Tabs>

    <div v-if="currentTabPane === 'detail'">
      <purchaseInfo
        v-if="orderId !== 0"
        :orderId="orderId"
        :products="products"
      />
    </div>
    <div v-else-if="currentTabPane === 'orderChangeDetail'">
      <changeRecord v-if="orderId !== 0" :orderId="orderId"></changeRecord>
    </div>
    <div v-else>
      <component
        :is="getTabpaneComponent(curCategory.categoryId)"
        :isOrder="false"
        :schedules="curCategory.schedules"
        :orderId="orderId"
      ></component>
    </div>

    <Spin fix size="large" v-if="spinShow">
      <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
      <div>数据加载中...</div>
    </Spin>
  </Modal>
</template>

<script>
import purchaseInfo from '@/components/order/details/PurchaseInfo2'
import plat from '@/components/order/details/Plat'
import electronic from '@/components/order/details/Electronic'
import train from '@/components/order/details/Train'
import theme from '@/components/order/details/Theme'
import group from '@/components/order/details/Group'

import changeRecord from './ChangeRecord'
import { buildCategoriesOrder } from '@/utils/orderUtils'
import { getProductPrice } from '@/api/order/orderprice'

export default {
  components: {
    purchaseInfo,
    plat,
    train,
    theme,
    electronic,
    group,
    changeRecord
  },
  data () {
    return {
      modalShow: false,
      spinShow: false,
      orders: [],
      chkOrderId: '',
      orderId: 0,
      currentTabPane: 'detail',
      categories: [],
      products: [],
      curCategory: null
    }
  },
  methods: {
    init (orders) {
      this.orders = orders
      this.chkOrderId = orders[0].id + ''
      this.orderId = parseInt(this.chkOrderId)
      this.modalShow = true
      this.spinShow = true
      // this.orderId = orderId
      this.products = []
      this.currentTabPane = 'detail'
      this.initOrderDetail()
    },
    initOrderDetail () {
      // 获取订单的价格清单和档期明细
      getProductPrice({ orderId: this.orderId }).then((res) => {
        this.products = res
        const categories = buildCategoriesOrder(res)
        categories.forEach((item) => {
          item.name = item.category + '采购详情'
        })
        categories.unshift({
          category: 'orderChangeDetail',
          name: '变更记录',
          categoryId: 99999
        })
        categories.unshift({
          category: 'detail',
          name: '采购详情',
          categoryId: 0
        })
        this.categories = categories.map((x) => {
          return Object.assign({}, x, { shown: false })
        })
        this.spinShow = false
      })
    },

    getTabpaneComponent (category) {
      const componentMap = {
        1: 'plat', // 传统媒体，包含列车
        2: 'train', // 列车
        4: 'theme', // B类， 主题媒介
        7: 'group', // 套装
        8: 'electronic' // 电子媒体
      }
      return componentMap[category]
    }
  },
  watch: {
    currentTabPane: function () {
      if (this.currentTabPane === 'orderChangeDetail') {
      } else if (this.currentTabPane !== 'detail') {
        this.curCategory = this.categories.find(
          (x) => x.category === this.currentTabPane
        )
      }
    },
    chkOrderId: function () {
      this.orderId = parseInt(this.chkOrderId)
      this.products = []
      this.spinShow = true
      this.currentTabPane = 'detail'
      this.initOrderDetail()
    }
  }
}
</script>
