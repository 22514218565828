import request from '@/utils/requestV2'
const qs = require('qs')

// 删除单/多个文件
export function deleteFiles (data) {
  return request({
    url: '/ooh-scp/v1/contractfile/deletefiles',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取文件集合
export function getFileList (data) {
  return request({
    url: '/ooh-scp/v1/contractfile/getfilelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
