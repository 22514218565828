<template>
  <Card v-if="value&&value.length>0">
    <p slot="title">付款变化 </p>
    <div>
      <Row
        type="flex"
        align="middle"
        justify="center"
        :gutter="18"
      >
        <i-col span="11">
          <i-table
           stripe
            :data="from"
            :columns="columns"
          ></i-table>
        </i-col>

        <i-col span="2" class="text-center">
          <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
        </i-col>

        <i-col span="11">
          <i-table
           stripe
            :data="to"
            :columns="columns"
          ></i-table>
        </i-col>
      </Row>
    </div>

  </Card>
</template>

<script>

export default {
  props: {
    value: Array
  },
  data () {
    return {
      columns: [
        { title: '付款日期', key: 'date' },
        { title: '金额', key: 'amount' }
      ],
      from: [],
      to: []
    }
  },
  created () {
    this.value.forEach(item => {
      this.from = this.from.concat(item.from)
      this.to = this.to.concat(item.to)
    })
  }
}
</script>
