<style>
.serviceRemark {
  /* width: 120px; */
  /* height: 50px; */
  padding: 5px;
  border: 1px solid #5cadff;
  border-radius: 3px;
  color: #808695;
  font-weight: 400;
  position: fixed;
  top: 65px;
  background-color: papayawhip;
  z-index: 999;
}
</style>

<template>
  <div>
    <!-- <div class="text-center workplatform-title">媒体广告发布</div> -->
    <Row class="p-b-10">
      <i-col span="12">
        <span  class="workplatform-title">{{ contract.contractName }}</span>
        <span class="m-l-10 workplatform-title">{{ contract.code }}</span>
        <span class="m-l-10 " v-if="contract.toBid>=0">(已转化为合同{{contract.toCode}})</span>
        <span class="m-l-10 workplatform-title" v-if="contract.fromBid>=0">(由确认单{{contract.fromCode}}转化)</span>
         <Tag
          class="m-l-5"
          color="blue"
          >{{contract.contractCategoryName}}</Tag
        >
         <Tag
          class="m-l-5"
          color="green"
          v-if="contract.salePolicy===2"
          >{{contract.salePolicyName}}</Tag
        >
        <!-- <Tag class="m-l-5" color="green" v-if="!showDisCount">
          {{contract.contractCategoryName}}
        </Tag>
        <Tag
          class="m-l-5"
          color="blue"
          v-else-if="contract.contractCategory === 2"
          >{{contract.contractCategoryName}}</Tag
        >
        <Tag class="m-l-5" color="blue" v-else-if="contract.type === 3"
          >代理商合同</Tag
        > -->
      </i-col>
      <i-col span="12" class="text-right" >
        <Row>
          <i-col span="24"><label class="title">创建时间 </label><label>{{ contract.createTime }}</label></i-col>

          <i-col span="24" v-if="contract.lastApprovedTime"><label class="title">最后审核时间 </label><label>{{ contract.lastApprovedTime }}</label></i-col>
        </Row>

        </i-col
      >
    </Row>
    <Row class="p-b-10">
         <!-- <i-col span="24">
        <span  class="title"> 发布类型 </span>
        <span>{{ contract.contractCategoryName||'无' }}</span>
      </i-col> -->
         <!-- <i-col span="24" v-if="contract.salePolicy===2">
        <span  class="title"> 发布标记 </span>
        <span>{{ contract.salePolicyName }}</span>
      </i-col> -->
      <i-col span="24">
        <span  class="title"> 甲方 </span>
        <span>{{ contract.firstParty||'无' }}</span>
      </i-col>
      <i-col span="24">
        <span  class="title">乙方 </span>
        <span>{{ contract.secondParty }}</span>
      </i-col>
    </Row>

    <Tabs
      :class="theme === 'black' ? 'text-white' : ''"
      @on-click="handleTabClick"
    >
      <TabPane label="基础详情">
        <p class="p-t-10 p-b-5 workplatform-title">甲方信息</p>
        <Row v-if="contract.customerInfoBean.name">
          <i-col span="8"
            ><span  class="title">公司名称 </span
            >{{ contract.customerInfoBean.name }}</i-col
          >
          <i-col span="8"
            ><span  class="title">公司地址 </span
            >{{ contract.customerInfoBean.address }}</i-col
          >
          <i-col span="8"
            ><span  class="title">联系人 </span
            >{{ contract.customerInfoBean.contact }}</i-col
          >
          <i-col span="8"
            ><span  class="title">联系人电话 </span
            >{{ contract.customerInfoBean.tel }}</i-col
          >
          <i-col span="8"
            ><span  class="title">开户银行 </span
            >{{ contract.customerInfoBean.bank }}</i-col
          >
          <i-col span="8"
            ><span  class="title">银行账号 </span
            >{{ contract.customerInfoBean.bankAccount }}</i-col
          >
          <i-col span="8"
            ><span  class="title">公司税号 </span
            >{{ contract.customerInfoBean.code }}</i-col
          >
        </Row>
         <Row v-else>
          <i-col span="24">
            无
          </i-col>
        </Row>

        <p class="p-b-5 p-t-20 workplatform-title">订购概览</p>
        <i-table
         stripe
          :data="products"
          :columns="productColumns"
          :summary-method="handleSummary"
          show-summary
        ></i-table>

        <p class="p-t-20 p-b-5  workplatform-title " >付款信息</p>
        <div class="p-b-10"  >
          按照约定，甲方共需向乙方支付媒介使用费、服务费用共计
          <span class="money">
            {{ formatMoney(contract.amount) }}(
            {{ formatUpperMoney(contract.amount) }}) </span
          >。
          <span v-show="contract.paymentBeanList.length > 0"
            >其中，甲方必须</span
          >

          <div
            v-for="(payItem, i) in contract.paymentBeanList"
            :key="payItem.id"
          >
            {{ i + 1 }}. 在 {{ payItem.date }} 前支付
            <span class="money">
              {{ formatMoney(payItem.amount) }} 元({{
                formatUpperMoney(payItem.amount)
              }}
              )
            </span>
            <span v-if="payItem.mark.length>0">，注：{{payItem.mark}}</span>
          </div>
        </div>

        <span class="p-t-10 p-b-10  workplatform-title">其他</span>
        <Row>
          <i-col span="24">
            备注：{{ contract.mark === "" ? "无" : contract.mark }}
          </i-col>
        </Row>
      </TabPane>

      <TabPane label="订购详情">
        <p >
          共计包含<span style="color:#ee7c4b;">{{ orderCount }}</span>
          个订单，如下:
        </p>

        <div v-for="(order, i) in orders" :key="order.id">
          <p class="hrHead ">
            <span  class="title">{{ i + 1 }}. 订单编号: </span>{{ order.code }}<span  class="title">， 投放品牌：</span>{{ order.brandName }}<span  class="title">， 投放档期：</span>{{
              order.startDate
            }}
            至 {{ order.endDate }}， (备注：{{ order.remark || "未填写备注" }})

            <a class="m-l-10" @click="handleShowOrder(order.id)">查看订单详情</a>
          </p>

          <Row  class="table-title">
            <i-col span="3" class="text-center p-t-5">发布档期</i-col>
            <i-col span="21">
              <Row class="table-title">
                <i-col span="4">产品名称</i-col>
                <i-col span="2">采购数量</i-col>
                <i-col span="5">媒体费刊例</i-col>
                <i-col span="5">执行价</i-col>
                <i-col span="4">服务费刊例</i-col>
                <i-col span="4">服务费签约价</i-col>
              </Row>
            </i-col>
          </Row>
          <priceDetail
            v-for="item in order.category"
            :showDisCount="showDisCount"
            :showActualPrice="true"
            :key="item.categoryId"
            :schedules="item.schedules"
          ></priceDetail>
        </div>
      </TabPane>
      <TabPane label="附件">
        <div v-if="contract.versionId">
          <div class="  workplatform-title">销售个人上传</div>
          <files
            :fileList="attachmentfiles"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
          <div class="m-t-10 workplatform-title">管理员上传</div>
          <files
            :fileList="signfiles"
            :downloadUrl="'/ooh-scp/v1/contractfile/downloadfile'"
          ></files>
        </div>
      </TabPane>
      <TabPane label="变更记录" name="record">
        <change-record :id="contract.contractId" v-if="showRecord"></change-record>
      </TabPane>
      <TabPane label="开票详情" name="invoice" v-if="fetched">
        <invoice :id="contract.contractId" v-if="invoiceShown"></invoice>
      </TabPane>
      <TabPane label="付款详情" name="payment" v-if="fetched">
        <payments :id="contract.contractId" v-if="paymentShown" :serviceType="1"></payments>
      </TabPane>
      <TabPane label="绩效预览" name="commission" v-if="fetched && showCommissionPreview">
        <commission-preview
          :payments="contract.paymentBeanList"
          :contractId="contract.contractId"
          :versionId="contract.versionId"
          v-if="showCommissionPreview"
        ></commission-preview>
      </TabPane>
      <TabPane label="作废详情" v-if="(contract.status === -1||contract.existInvalidInfo) && fetched">
        <invalid-detail :relateId="contract.contractId" :processType="processType"></invalid-detail>
      </TabPane>
    </Tabs>

    <!-- 弹窗 订单详情 -->
    <OrderDetail ref="orderDetail" />
    <MultOrderDetail ref="multOrderDetail" />

    <Modal v-model="showServiceRecordModal" :width="600" footer-hide>
      <p slot="header" class="workplatform-title">
        <span>服务费明细</span>
      </p>
      <i-table stripe :data="serviceDetail" :columns="serviceColumns"></i-table>
    </Modal>
  </div>
</template>

<script>
import InvalidDetail from '@/components/approval/InvalidDetail'
import Files from '@/components/common/FilesV2'
import CommissionPreview from '@/components/contract/contractDetail/detail/CommissionPreview'
import Invoice from '@/components/contract/contractDetail/detail/Invoice'
import Payments from '@/components/contract/contractDetail/detail/Payments'
import PriceDetail from '@/components/contract/contractDetail/detail/PriceDetail'
import ChangeRecord from '@/components/contract/contractDetail/detail/ChangeRecord'
import OrderDetail from '@/components/order/common/ModalOrderDetail'
import MultOrderDetail from '@/components/order/common/ModalMultOrderDetail'

import { parseUpperMoney } from '@/utils'
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { buildCategories } from '@/utils/orderUtils'

import { checkContractDetailByVersionId, getProcessTypeByVersionId } from '@/api/scp/contract'

import { getFileList } from '@/api/scp/contractfile'
import {
  getOrderProductList,
  getOrderScheduleCategoryList
} from '@/api/scp/contractorder'
import { getOrderList } from '@/api/order/order'

const MAX_CELL_ROW = 2

export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    versionId: {
      type: [Number, String],
      required: false
    },
    theme: {
      type: String,
      default: 'black'
    }
  },
  components: {
    InvalidDetail,
    CommissionPreview,
    Invoice,
    Payments,
    PriceDetail,
    Files,
    ChangeRecord,
    OrderDetail,
    MultOrderDetail
  },
  data () {
    return {
      contract: {
        customerInfoBean: {},
        paymentBeanList: []
      },
      products: [],
      orderCount: 0,
      orders: [],
      productColumns: [
        { title: '资源名称', key: 'goodName' },
        {
          title: '采购数量',
          render: (h, data) => {
            const types = []
            data.row.scheduleQuantityList.forEach((item) => {
              types.push(
                h('p', [
                  h('span', GetCurrentSchedule(item.startDate, item.endDate)),
                  h(
                    'span',
                    { class: 'text-orange' },
                    '(' +
                      item.signNormalQuantity +
                      ')'
                  )
                ])
              )
            })

            let showMore
            const tooMany =
              data.row.scheduleQuantityList.length - MAX_CELL_ROW > 1
            const simple = tooMany
              ? types.slice(0, MAX_CELL_ROW)
              : types.slice(0)

            if (tooMany) {
              showMore = h(
                'a',
                {
                  on: {
                    click: () => {
                      data.row.showAll = !data.row.showAll
                    }
                  }
                },
                [
                  h('Icon', {
                    props: {
                      type: data.row.showAll
                        ? 'ios-arrow-down'
                        : 'ios-arrow-up',
                      color: '#4285f4',
                      size: 'large'
                    }
                  }),
                  h(
                    'span',
                    { style: { color: '#4285f4' } },
                    data.row.showAll ? '显示更多' : '隐藏'
                  )
                ]
              )
            }
            simple.push(showMore)
            types.push(showMore)
            return h('div', data.row.showAll ? simple : types)
          }
        },
        {
          title: '媒体费刊例',
          key: 'normalUsePrice',
          render: (h, data) => {
            return h('div', [
              h('span', { class: 'money' }, this.formatMoney(data.row.normalUsePrice)),
              h(
                'span',
                '(' + data.row.usePriceDiscount + ')'
              )
            ])
          }
        },
        {
          title: '媒体发布费',
          key: 'signNormalPrice',
          render: (h, data) => {
            let disCountText
            if (this.showDisCount) {
              disCountText = h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(data.row.normalUsePrice, data.row.signNormalPrice)
              )
            }
            let warning
            // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
            if (data.row.lowerSettlementPrice) {
              warning = h(
                'p',
                {
                  style: {
                    color: '#ef4f4f',
                    'font-size': 'small'
                  }
                },
                '低于最低折扣'
              )
            }
            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.signNormalPrice)
              ),
              disCountText,
              warning
            ])
          }
        },
        {
          title: '服务费签约价',
          key: 'signServicePrice',
          render: (h, data) => {
            return h('div', [
              h(
                'span', { class: 'money' },
                this.formatMoney(data.row.signServicePrice)
              ),
              h(
                'span',
                { class: 'discount' },
                this.computeShowDiscount(
                  data.row.totalServicePrice,
                  data.row.signServicePrice
                )
              )
            ])
          }
        },

        {
          title: '小计',
          key: 'subtotalPrice',
          render: (h, data) => {
            return h(
              'span', { class: 'money' },
              this.formatMoney(data.row.subtotalPrice)
            )
          }
        },
        {
          title: '操作',
          key: 'operate',
          render: (h, data) => {
            return h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.showServiceDetail(data.row.goodServiceFeeVOList)
                  }
                }
              },
              '服务费明细'
            )
          }
        }

      ],

      serviceDetail: [],
      serviceColumns: [
        { title: '服务费名称', key: 'serviceItemName', width: 130 },
        { title: '服务费类型', key: 'typeName', align: 'center' },
        {
          title: '资源数量',
          key: 'serviceQuantity',
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.serviceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '刊例单价',
          key: 'price',
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.price + '/' + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约数量',
          key: 'signServiceQuantity',
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h('span', {}, data.row.signServiceQuantity + data.row.serviceQuantityName)
            ])
          }
        },
        {
          title: '签约价',
          key: 'signServiceAmount',
          align: 'center',
          render: (h, data) => {
            return h('div', [
              h(
                'span',
                { class: 'money' },
                this.formatMoney(data.row.signServiceAmount)
              )
            ])
          }
        }
      ],
      showServiceRecordModal: false,

      commission: {},
      invoiceShown: false,
      paymentShown: false,
      fetched: false,
      showRecord: false,
      schedulePrice: [],

      attachmentfiles: [],
      signfiles: [],
      processType: 23
    }
  },
  methods: {

    showServiceDetail (detail) {
      this.serviceDetail = detail
      this.showServiceRecordModal = true
    },
    getDetail () {
      checkContractDetailByVersionId({ versionId: this.versionId || this.id }).then((res) => {
        this.fetched = true
        this.$emit('on-update', res)
        this.contract = res
        getProcessTypeByVersionId({ versionId: this.versionId || this.id, processTypeFeature: 1 }).then(processType => {
          this.processType = processType
        })
        this.getPurchaseItems()
        this.getOrderList()
        this.getFiles()
      })
    },
    getPurchaseItems () {
      const queryModel = {
        contractId: this.id,
        version: this.versionId || this.contract.versionId
      }

      // 获取订购概览
      getOrderProductList(queryModel).then((orderProducts) => {
        this.products = []
        let lowerSettlementPrice = false
        let flag = false
        orderProducts.forEach((element) => {
          // 计算各项汇总值

          element.subtotalPrice =
              element.signNormalPrice + element.signServicePrice

          // 公益、二类商业都不显示这条消息。   其余的验证是否低于最低限价
          lowerSettlementPrice =
              this.showDisCount &&
              element.normalSellPrice + element.recommendSellPrice <
                element.settlementPrice &&
              this.contract.contractCategory !== 2

          if (lowerSettlementPrice) {
            flag = true
          }

          this.products.push(
            Object.assign({}, element, {
              lowerSettlementPrice: lowerSettlementPrice,
              showAll: true
            })
          )
        })

        this.$emit('on-lowerSettlementPrice', flag)
      })
    },
    getOrderList () {
      const queryModel = {
        contractId: this.id,
        version: this.versionId || this.contract.versionId
      }
      getOrderScheduleCategoryList(queryModel).then((orders) => {
        this.orderCount = orders.length
        const orderIds = orders.map((o) => o.orderId)
        if (orders.length) {
          getOrderList({ orderIdStr: JSON.stringify(orderIds) }).then(
            (orderDetails) => {
              this.orders = orderDetails.map((order) => {
                const priceSetting = orders.find(
                  (od) => od.orderId === order.id
                )
                order.category = buildCategories(
                  priceSetting.signPriceDetail
                )
                return order
              })
            }
          )
        }
      })
    },
    getFiles () {
      if (this.contract.versionId) {
        getFileList({ relateId: this.versionId || this.contract.versionId, type: 'CONTRACT_ATTACHMENT_FILE' }).then(res => {
          this.attachmentfiles = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
        getFileList({ relateId: this.versionId || this.contract.versionId, type: 'CONTRACT_SIGN_FILE' }).then(res => {
          this.signfiles = res.map(x => {
            return Object.assign({}, x, { url: process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/downloadfile?fileId=' + x.id })
          })
        })
      }
    },
    handleShowOrder (orderId) {
      // 根据菜单动态跳转链接
      this.$refs.orderDetail.init(orderId)
    },
    handleShowMultOrder () {
      // 根据菜单动态跳转链接
      this.$refs.multOrderDetail.init(this.orders)
    },
    showOrderDetail () {

    },
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'normalUsePrice',
        'signNormalPrice',
        'signServicePrice',
        'subtotalPrice'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (number) {
      return parseUpperMoney(number)
    },
    computeSchedule (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    handleTabClick (name) {
      if (name === 'invoice') {
        this.invoiceShown = true
      }
      if (name === 'payment') {
        this.paymentShown = true
      }
      if (name === 'record') {
        this.showRecord = true
      }
    },
    computeShowDiscount (denominator, member) {
      if (denominator > 0) {
        const discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        const showDisCount = discount.toFixed(2)
        // Math.floor()，不四舍五入 ，向下取整
        // const showDisCount = Math.floor(discount * 100) / 100

        return '(' + showDisCount + '折)'
      } else {
        return ''
      }
    }
  },
  created () {
    this.getDetail()
  },
  computed: {
    showCommissionPreview: function () {
      // if (this.contract.contractCategory === 2) {
      //   return true
      // }
      // return this.contract.commissiontypeId > 0 && this.contract.channelType === 0
      return this.$store.getters.token.publisherSetting.companyPerformance
    },
    showDisCount () {
      return !this.contract.public // 公益不显示折扣
    }
  }
}
</script>
