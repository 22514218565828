<template>
    <div>
        <Timeline>
            <TimelineItem v-for="(item,i) in assignItems " :key="i">
                <p class="time">{{item.date}} 前</p>
                <p class="content">甲方支付乙方{{formatMoney(item.money) }}
                    <span class="m-l-20 text-red" > 支付合同佣金 {{formatMoney(item.commission)}}</span>
                </p>
            </TimelineItem>
        </Timeline>
    </div>

</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
export default {
  props: {
    assignItems: {
      type: Array
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>
