import request from '@/utils/requestV2'
const qs = require('qs')

// 获取代理商佣金汇总
export function getchannelcommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getchannelcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取代理商佣金明细
export function getchannelcontractcommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getchannelcontractcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取代理商下销售佣金明细
export function getchannelsalercommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getchannelsalercommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取自有销售绩效汇总
export function getpublishersalercommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getpublishersalercommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取自有销售下合同明细
export function getpublishersalercontractcommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getpublishersalercontractcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取单个合同实时的绩效详情
export function getRealTimeContractCommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getrealtimecontractcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取单个合同实时的绩效详情
export function getContractExpectCommission (data) {
  return request({
    url: '/ooh-scp/v1/perfcommission/getcontractexpectcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}
