import request from '@/utils/requestV2'
const qs = require('qs')

// 获取合同分类类型
export function getcategorylist (data) {
  return request({
    url: '/ooh-scp/v1/scpbusiness/getcategorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取合同分类类型
export function getbusinessinvalidinfo (data) {
  return request({
    url: '/ooh-scp/v1/scpbusiness/getbusinessinvalidinfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
