import request from '@/utils/requestV2'

// 查询合同的账单列表,包括结算数据
export function listContractBillInfo (data) {
  return request({
    url: '/ooh-dw/v2/bill/listContractBillInfo',
    method: 'get',
    params: data
  })
}
// 统计合同的账单金额,包括结算数据
export function countContractBillAmount (data) {
  return request({
    url: '/ooh-dw/v2/bill/countContractBillAmount',
    method: 'get',
    params: data
  })
}
