<template>
  <div>
    <Row   class="hrHead" v-for="(sc,i) in schedules" :key="i" >
      <i-col span="3" class="p-t-5">
       <span>{{ getSchedule(sc) }}</span>
      </i-col>
      <i-col span="21">
        <Row v-for="(product,i) in sc" :key="product.goodId+i" :class="(i%2 === 0)?'table-row-1':'table-row-2'">
          <i-col span="4">{{product.goodName}}</i-col>
          <i-col span="2">{{product.signNormalQuantity}}</i-col>
          <i-col span="5">{{formatMoney(product.normalUsePrice)}}({{product.usePriceDiscount}})</i-col>
          <i-col span="5" v-if="showActualPrice">
            <span>{{formatMoney(product.signNormalPrice)}} </span>
            <span v-if="showDisCount" style="color:#ee7c4b;">
              {{computeShowDiscount(product.normalUsePrice, product.signNormalPrice)}}
            </span>
          </i-col>
          <i-col span="4" v-else>-</i-col>
          <!-- 显示服务费刊例 -->
          <i-col span="4" v-if="showActualPrice">
            <span>{{formatMoney(product.totalServicePrice)}} </span>
          </i-col>
          <i-col span="4" v-else>-</i-col>

          <!-- 显示服务费签约价 -->
          <i-col span="4" v-if="showActualPrice">
            <span>{{formatMoney(product.signServicePrice)}} </span>
            <span v-if="showDisCount" style="color:#ee7c4b;">
              {{computeShowDiscount(product.totalServicePrice, product.signServicePrice)}}
            </span>
          </i-col>
          <i-col span="4" v-else>-</i-col>

          <!-- <i-col span="2">{{showActualPrice?formatMoney(product.monitorPrice + product.fixProtectSignPrice + product.industryProtectSignPrice + product.breakScheduleSignPrice):'-'}}</i-col> -->
        </Row>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { GetCurrentSchedule } from '@/utils/dateFormat'
export default {
  props: {
    schedules: {
      type: Array,
      required: true
    },
    showDisCount: Boolean,
    showActualPrice: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    getSchedule (products) {
      if (products.length > 0) {
        var firstProduct = products[0]
        return GetCurrentSchedule(firstProduct.startDate, firstProduct.endDate)
      }
    },
    computeShowDiscount (denominator, member) {
      let showLabel = ''
      let discount = 0
      let showDisCount = 0

      if (denominator > 0) {
        discount = (member / denominator) * 10

        // toFixed()方法, 四舍五入
        showDisCount = discount.toFixed(2)

        // Math.floor()，不四舍五入 ，向下取整
        // showDisCount = Math.floor(discount * 100) / 100

        if (discount < 10) {
          showLabel = '(' + showDisCount + '折)'
        }
      }

      return showLabel
    }
  }

}
</script>

<style  scoped >
.schedule{
 font-size: 300;
 font-weight: bold;
}
</style>
