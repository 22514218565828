<template>
  <div>
    <i-table stripe  :data="list" :columns="columns"></i-table>
     <!-- <div class="paging_style">
        <Page :total="total" :page-size="query.pageSize" @on-change="handlePageChange" show-total show-elevator></Page>
    </div> -->

  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
// import { getDistributionPage } from '@/api/scp/publishercash'
import { getReceiveList } from '@/api/scp/contract'
import { getStatementReceiveList } from '@/api/scp/statement'
export default {
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    serviceType: { // 服务类型：1合同，2结算单
      type: Number,
      required: true
    }
  },
  data () {
    return {
      list: [],
      columns: [
        { title: '日期', key: 'date' },
        {
          title: '金额',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.amount))
          }
        }
      ]
      // query: { pageNumber: 1, pageSize: 10 }
      // total: 0
    }
  },
  methods: {
    getPayments () {
      // this.query.contractId = this.id
      if (this.serviceType === 1) {
        getReceiveList({ contractId: this.id }).then(res => {
          this.list = res
        // this.total = res.totalRow
        })
      } else {
        getStatementReceiveList({ statementId: this.id }).then(res => {
          this.list = res
        })
      }
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.getPayments()
    }

  },
  created () {
    this.getPayments()
  }

}
</script>
