<template>
  <div v-if="value&&value.length>0">
    <Card>
      <p slot="title">基础信息变更</p>
      <Row type="flex" align="middle" justify="center" :gutter="18">
        <i-col span="11">
          <i-table
           stripe
            :data="fromArray"
            :columns="columns"
          ></i-table>
        </i-col>

        <i-col span="2" class="text-center">
          <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
        </i-col>

        <i-col span="11">
          <i-table
           stripe
            :data="toArray"
            :columns="columns"
          ></i-table>
        </i-col>
      </Row>
    </Card>
  </div>
</template>

<script>
export default {
  props: {
    value: Array
  },
  data () {
    return {
      columns: [
        { title: '字段', key: 'label' },
        { title: '值', key: 'value' }
      ]
    }
  },
  computed: {
    fromArray: function () {
      return this.value.map(item => {
        return {
          label: item.filedDesc,
          value: item.from
        }
      })
    },
    toArray: function () {
      return this.value.map(item => {
        return {
          label: item.filedDesc,
          value: item.to
        }
      })
    }
  }

}
</script>
