<template>
  <Card>
    <p slot="title">绩效类型{{setting.name}}</p>
    <Row>
      <i-col span="6">
        <strong>免息天数:</strong>
        <span> {{setting.postponeDay}} </span>
      </i-col>
      <i-col span="6">
        <strong>奖息日利率:</strong>
        <span>{{(setting.aheadDailyRate*100).toFixed(2) }}%</span>
      </i-col>
      <i-col span="6">
        <strong>罚息日利率:</strong>
        <span>{{(setting.delayedDailyRate*100).toFixed(2) }}%</span>
      </i-col>
      <i-col span="6">
        <strong>计算方式:</strong>
        <Tag color="success">{{setting.commissionSettlementFormName }}</Tag>
      </i-col>
    </Row>
    <h4>普通产品配置 <small class="text-red">将会按照【（发布费执行价-发布费结算价）*发布费绩效系数+结算价*发布费提成系数+服务费*服务费提成系数】 与 【发布费执行价*发布费固定返点系数+服务费*服务费固定返点系数 】中较大的计算 </small> </h4>
    <Row>
          <i-col span="4">
            <strong>发布费绩效系数</strong>
            <span>{{setting.normal.basic}}</span>
          </i-col>
           <i-col span="4">
            <strong>发布费提成系数</strong>
            <span>{{setting.normal.coefficient}}</span>
          </i-col>
           <i-col span="4">
            <strong>服务费提成系数</strong>
            <span>{{setting.normal.fixedPoint}}</span>
          </i-col>
           <i-col span="6">
            <strong>发布费固定返点</strong>
            <span>{{setting.normal.sellFixed}}</span>
          </i-col>
           <i-col span="6">
            <strong>服务费固定返点</strong>
            <span>{{setting.normal.serviceFixed}}</span>
          </i-col>
        </Row>
    <div v-show="specialProducts.length>0">
      <h4>特殊产品绩效配置 <small class="text-red">将会按照 【发布费执行价*发布费固定返点系数+服务费*服务费固定返点系数】计算 </small></h4>
       <Row v-for="(special,i) in specialProducts " :key="i">
            <i-col span="2">
              <strong>发布费固定返点</strong>
              <span>{{special.productGroupCommissionAlgorithm.usefee.fixedPoint}}</span>
            </i-col>
             <i-col span="2">
              <strong>服务费固定返点</strong>
              <span>{{special.productGroupCommissionAlgorithm.servicefee.fixedPoint}}</span>
            </i-col>
            <i-col span="12">
              <strong>适用产品</strong>
              <Tag
                color="red"
                v-for="product in special.products"
                :key="product.id"
              >{{product.name}}</Tag>
            </i-col>
          </Row>
    </div>

    <divider dashed>产品绩效列表</divider>
    <i-table stripe  :data="commissions" :columns="columns"></i-table>
    <divider dashed v-if="payments.length!==0">绩效发放时间</divider>
    <Row v-if="payments.length!==0" class="p-b-10">
      <i-col span="24">
        <commissionlist :assignItems="commissionsToAssign"></commissionlist>
      </i-col>
    </Row>
  </Card>
</template>

<script>
import { getContractExpectCommission } from '@/api/scp/perfcommission'
import commissionlist from './Commissionlist'

import { toMoney } from '@/utils/wnumb_own'
import { getLaterDate } from '@/utils/index'

export default {
  props: {
    contractId: {
      type: [String, Number],
      required: true
    },
    versionId: {
      type: [String, Number],
      required: true
    },
    payments: {
      type: Array,
      required: true
    }
  },
  components: {
    commissionlist
  },
  data () {
    return {
      columns: [
        { title: '产品名称', key: 'productName' },
        {
          title: '发布费结算价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.totalSettlementPrice))
          }
        },
        {
          title: '发布费执行价',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.sellPrice))
          }
        },
        {
          title: '服务费',
          render: (h, data) => {
            return h('span', { attrs: { class: 'money' } }, toMoney(data.row.serviceFee))
          }
        },
        { title: '绩效计算方式', key: 'desc', tooltip: true },
        {
          title: '发布费绩效',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.com))
          }
        },
        {
          title: '发布费提成',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.kpi))
          }
        },
        {
          title: '服务费提成',
          render: (h, params) => {
            return h('span', { style: { color: '#19be6b' } }, toMoney(params.row.serviceKpi))
          }
        },
        {
          title: '合计佣金',
          render: (h, params) => {
            return h('span', { style: { color: '#ed4014' } }, toMoney(params.row.total))
          }
        }
      ],
      typeName: '',
      setting: {
        normal: {}
      },
      products: []
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    getCommissionDetail () {
      getContractExpectCommission({ contractId: this.contractId, versionId: this.versionId }).then(res => {
        this.products = res.productList
        const setting = res.contractCommissiontypeSetting
        const normalSettings = setting.normalsetting.productGroupCommissionAlgorithm.commissionAlgorithmList
        const fixed = normalSettings.find(x => x.type === 2)
        const basic = normalSettings.find(x => x.type === 1)
        const normalBasic = Object.assign({}, basic.servicefee, basic.usefee)
        const normalFixed = { serviceFixed: fixed.servicefee.fixedPoint, sellFixed: fixed.usefee.fixedPoint }
        const normal = Object.assign({}, normalBasic, normalFixed)
        this.setting = Object.assign({}, setting, { normal: normal })
      })
    }
  },
  computed: {
    commissions: function () {
      const commissions = []
      this.products.forEach(product => {
        const total = { sellPrice: 0, totalSettlementPrice: 0, serviceFee: 0 }
        product.productPeriodList.forEach(period => {
          total.sellPrice += period.useSignAmount
          total.totalSettlementPrice += period.useCost
          total.serviceFee += period.serviceSignAmount
        })
        const commission = {}
        if (product.commissionAlgorithm.type === 2) {
          commission.kpi = total.sellPrice * product.commissionAlgorithm.usefee.fixedPoint
          commission.serviceKpi = total.serviceFee * product.commissionAlgorithm.servicefee.fixedPoint
          commission.com = 0
          commission.desc = '发布费*' + product.commissionAlgorithm.usefee.fixedPoint + '+服务费*' + product.commissionAlgorithm.servicefee.fixedPoint
          commission.total = commission.kpi + commission.com + commission.serviceKpi
        } else {
          commission.kpi = total.totalSettlementPrice * product.commissionAlgorithm.usefee.basic
          commission.com = (total.sellPrice - total.totalSettlementPrice) * product.commissionAlgorithm.usefee.coefficient
          commission.serviceKpi = total.serviceFee * product.commissionAlgorithm.servicefee.fixedPoint
          commission.desc = '(发布费执行价-发布费结算价）*' + product.commissionAlgorithm.usefee.coefficient + '+发布费结算价*' + product.commissionAlgorithm.usefee.basic + '+服务费*' + product.commissionAlgorithm.servicefee.fixedPoint
          commission.total = commission.kpi + commission.com + commission.serviceKpi
        }
        commissions.push(Object.assign({}, total, commission, { productName: product.productName }))
      })
      return commissions
    },
    commissionsToAssign: function () {
      let items = []
      const method = this.setting.commissionSettlementForm
      switch (method) {
        case 10:
          items = this.schedules
          break
        case 30: // 合同执行和回款
          var scheduleLastDate = getLaterDate(this.schedules.map(x => x.date))
          var paymentLastDate = getLaterDate(this.payments.map(x => x.date))
          var lastDate = getLaterDate([scheduleLastDate, paymentLastDate])
          items = [
            { date: lastDate, money: this.total.money, commission: this.total.commission }
          ]
          break
        case 40: // 按回款计
          items = [
            {
              date: getLaterDate(this.payments.map(x => x.date)),
              money: this.total.money,
              commission: this.total.commission
            }
          ]
          break

        default:
          break
      }
      return items
    },
    schedules: function () {
      const scheduleMap = new Map()
      this.products.forEach(product => {
        product.productPeriodList.forEach(schedule => {
          const item = scheduleMap.get(schedule.endDate) || { date: schedule.endDate, money: 0, commission: 0 }
          item.money += (schedule.useSignAmount + schedule.serviceSignAmount)
          if (product.commissionAlgorithm.type === 2) {
            item.commission += schedule.useSignAmount * product.commissionAlgorithm.usefee.fixedPoint
            item.commission += schedule.serviceSignAmount * product.commissionAlgorithm.servicefee.fixedPoint
          } else {
            item.commission += (schedule.useSignAmount - schedule.useCost) * product.commissionAlgorithm.usefee.coefficient + schedule.useCost * product.commissionAlgorithm.usefee.basic
            item.commission += schedule.serviceSignAmount * product.commissionAlgorithm.servicefee.fixedPoint
          }
          scheduleMap.set(schedule.endDate, item)
        })
      })
      return [...scheduleMap.values()]
    },
    specialProducts: function () {
      const special = this.products
        .filter(x => x.commissionAlgorithm.type === 2 &&
      x.commissionAlgorithm.usefee.fixedPoint !== this.setting.normal.sellFixed &&
      x.commissionAlgorithm.servicefee.fixedPoint !== this.setting.normal.serviceFixed)
        .map(x => x.productId)
      const items = []
      if (this.setting.specialgroupsettingList) {
        this.setting.specialgroupsettingList.forEach(sp => {
          const products = []
          sp.productList.forEach(p => {
            if (special.includes(p.productId)) {
              products.push({
                id: p.productId,
                name: p.productName
              })
            }
          })
          if (products.length > 0) {
            items.push(Object.assign({}, sp, { products: products }))
          }
        })
      }
      return items
    },
    total: function () {
      const total = {
        money: 0,
        commission: 0
      }
      this.schedules.forEach(commission => {
        total.money += commission.money
        total.commission += commission.commission
      })
      return total
    }

  },
  created () {
    this.getCommissionDetail()
  }

}
</script>
