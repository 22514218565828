<template>
  <div class="m-t-10">
    <span v-if="nodes.length>0" class="workplatform-title">审批进度</span>
    <Steps :current="currentStep" class="m-b-20">
      <Step v-for="(node,i) in nodes" :key="i" :title="node.approvalnodeName" :content="node.approverUserName + '['+ node.statusName + ']'">
      </Step>
    </Steps>

    <span class=" workplatform-title">已有审批记录</span>
    <i-table stripe :data="records" :columns="titles"></i-table>

    <div v-if="withdrawRecords.length">
      <span class=" workplatform-title">撤回审批记录</span>
      <i-table stripe :columns="withdrawTitles" :data="withdrawRecords" size="small"></i-table>
    </div>

  </div>
</template>

<script>
import { getApprovalSchedule } from '@/api/approval/approvaltask'
import { formatApprovalResult } from '@/utils/tagStatus'

export default {
  props: {
    type: {
      required: true
    },
    relateId: {
      required: true
    }
  },
  data () {
    return {
      nodes: [],
      records: [],
      currentStep: 0,
      titles: [
        { title: '审批人', key: 'approverUserName' },
        { title: '结点名称', key: 'approvalnodeName' },
        { title: '创建时间', key: 'createTime' },
        { title: '审批时间', key: 'finishTime' },
        {
          title: '审批意见',
          render: (h, data) => {
            let isWarning
            if (data.row.processtype === 10 && data.row.taskSupplementFeature === 1) {
              isWarning = h('tag', { props: { color: 'blue', type: 'border' } }, data.row.taskSupplementFeatureName)
            }

            return h('div', [
              formatApprovalResult(h, data.row.approvalResult, data.row.approvalResultName),
              isWarning
            ])
          }
        },
        { title: '审批备注', key: 'comment' }
      ],
      withdrawRecords: [],
      withdrawTitles: [
        { title: '处理人', key: 'latestApproverUserName' },
        { title: '撤回时间', key: 'latestApproverFinishTime' },
        { title: '撤回理由', key: 'comment' }
      ]
    }
  },
  methods: {
    getProcessDetail () {
      var node = getApprovalSchedule({
        processtype: this.type,
        relateId: this.relateId,
        includeHistoryProcessinstance: false
      }).then(data => {
        this.nodes = data
      })
      var record = getApprovalSchedule({
        processtype: this.type,
        relateId: this.relateId,
        includeHistoryProcessinstance: true
      }).then(res => {
        this.records = res.filter(item => item.status && item.status === 1)
        this.withdrawRecords = res.filter(item => item.status && item.status === 2)
      })

      Promise.all([node, record]).then(() => {
        var lastRecord = this.records[this.records.length - 1] || {}
        this.currentStep =
          this.nodes.findIndex(
            node => node.approvalnodeId === lastRecord.approvalnodeId
          ) + 1
      })
    },
    getProcessCancelDetail (taskBean) { // 主动获取作废记录
      var node = getApprovalSchedule({
        processtype: taskBean.processtype,
        relateId: taskBean.relateId,
        includeHistoryProcessinstance: false,
        dataId: taskBean.dataId,
        processtypeFeature: taskBean.processtypeFeature
      }).then(data => {
        this.nodes = data
      })

      // 获取撤回审批的记录
      var record = getApprovalSchedule({
        processtype: taskBean.processtype,
        relateId: taskBean.relateId,
        includeHistoryProcessinstance: true, // 撤回标记
        dataId: taskBean.dataId,
        processtypeFeature: taskBean.processtypeFeature
      }).then(res => {
        this.records = res.filter(item => item.status && item.status === 1)
        this.withdrawRecords = res.filter(item => item.status && item.status === 2)
      })

      Promise.all([node, record]).then(() => {
        var lastRecord = this.records[this.records.length - 1] || {}
        this.currentStep =
          this.nodes.findIndex(
            node => node.approvalnodeId === lastRecord.approvalnodeId
          ) + 1
      })
    }
  },
  created () {
    if (this.type !== 'cancel') {
      this.getProcessDetail()
    }
  }
}
</script>
