<template>
  <div>
    <Timeline>
      <TimelineItem v-for="item in versionlist" :key="item.versionId">
        <p style="font-weight:bold;font-size:large">变更时间 {{item.approvedTime}}</p>
        <change-detail :detail="computeChangeFields(item.changeDetail)" ></change-detail>

      </TimelineItem>

    </Timeline>
    <p v-if="loading" class="text-center p-t-10" >加载中</p>
    <p v-else class="text-center p-t-10" v-show="versionlist.length === 0">
      当前{{status}}无变更记录
    </p>
  </div>
</template>

<script>
import changeDetail from '@/components/contractChange/ChangeDetail'
import { getVersionList } from '@/api/scp/contractchange'
import { getChangeFields } from '@/utils/contractChange'

export default {
  components: {
    changeDetail
  },
  props: {
    id: [Number, String],
    status: {
      type: String,
      default: '合同'
    }
  },
  data () {
    return {
      versionlist: [],
      loading: true
    }
  },
  methods: {
    getVersions () {
      getVersionList({ contractId: this.id }).then(res => {
        this.loading = false
        this.versionlist = res
      })
    },
    computeChangeFields (changeDetail) {
      return getChangeFields(changeDetail)
    }
  },
  created () {
    this.getVersions()
  }

}
</script>
