<template>
  <div>
    <p class="p-b-5">
      <Button type="success" size="small" class="m-l-5" @click="choiceDownLoadProgramme">下载</Button>
    </p>
    <Table :loading="loading"
         stripe :data="list" :columns="tableColumns"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary></Table>
    <Row>
      <i-col span='12'>
        <!-- <span style="height: 28px;line-height: 28px;">已选中{{feeitemIds.length}}条</span> -->
      </i-col>
      <i-col  span='12'>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-sizer
            :page-size-opts="[15,50,100,200,500]"
            :current="query.pageNumber"
            @on-change="handlePageChanged"
            @on-page-size-change="handlePageSizeChanged"></Page>
        </div>
      </i-col>
    </Row>

    <Modal v-model="showDownLoadProgrammeModal">
      <h3 class="p-b-10" slot="header">请选择下载类型</h3>
      <Row>
        <i-col span="24">
          <RadioGroup v-model="downLoadProgramme" vertical>
            <Radio label="1">
                <span>全部下载</span>
            </Radio>
            <Radio label="2">
                <span>勾选下载</span>
            </Radio>
        </RadioGroup>
        </i-col>
      </Row>

      <div slot="footer">
        <Button type="text" @click="showDownLoadProgrammeModal=false">取消</Button>
        <Button type="primary"  @click="choiceDownLoadProgramme">确认</Button>
      </div>
    </Modal>

    <Modal v-model="modalContract" :width="1200" footer-hide>
      <contract-detail v-if="modalContract" :id="contractId" :versionId="contractId"></contract-detail>
    </Modal>
  </div>
</template>

<script>
import ContractDetail from '@/components/contract/contractDetail/Contract'

import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule, ParseDate, ParseDateForMonth } from '@/utils/dateFormat'

import { downloadFileRequest } from '@/utils/download'

import { listContractBillInfo, countContractBillAmount } from '@/api/dw/bill'
// import { countContractAmount } from '@/api/dw/verify'

export default {
  components: {
    ContractDetail
  },
  data () {
    return {
      clientHeight: 0,
      total: 0,
      loading: false,

      query: {
        pageNumber: 1,
        pageSize: 15,
        companyId: 0,
        publisherId: this.$store.getters.token.userInfo.publisherId,
        startDate: '',
        endDate: '',
        queryString: ''
      },
      list: [],
      tableColumns: [
        // {
        //   type: 'selection',
        //   key: 'feeItemId',
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '代理商',
          key: 'companyName'
        },
        {
          title: '所属销售',
          key: 'userName'
        },
        {
          title: '合同编号',
          key: 'code',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.contractId = params.row.contractId
                    this.modalContract = true
                  }
                }
              }, params.row.code)
          }
        },
        {
          title: '合同名称',
          key: 'name'
        },
        {
          title: '客户',
          key: 'advertiserName'
        },
        {
          title: '合同起止日',
          key: 'startDate',
          render: (h, params) => {
            return h('div', [h('span', this.formatSchuedle(params.row).value)])
          }
        },

        {
          title: '合同总额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.amount)
              )
            ])
          }
        },
        {
          title: '已对账金额',
          key: 'confirmAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.confirmAmount)
              )
            ])
          }
        },

        {
          title: '已结算金额',
          key: 'settleAmount',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  class: 'money'
                },
                toMoney(params.row.settleAmount)
              )
            ])
          }
        },
        {
          title: '当前周期执行额',
          align: 'center',
          children: [
            {
              title: '媒介使用费',
              align: 'center',
              key: 'usePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.usePrice))
              }
            },
            {
              title: '服务费',
              align: 'center',
              key: 'servicePrice',
              render: (h, param) => {
                return h('span', {
                  class: 'money'
                }, toMoney(param.row.servicePrice))
              }
            }
          ]
        }
      ],

      feeitemIds: [], // 页面选中的所有项
      currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合
      currentPageSelectionIdArray: [],

      showDownLoadProgrammeModal: false,
      downLoadProgramme: '1',

      summaryData: null,
      modalContract: false,
      contractId: 0
    }
  },
  created () {
    this.clientHeight = document.documentElement.clientHeight - 220
  },
  methods: {
    loadSummaryData () {
      countContractBillAmount(this.query).then(res => {
        this.summaryData = res
        this.loadPage()
      })
    },
    loadPage () {
      this.loading = true
      const that = this
      listContractBillInfo(that.query).then((res) => {
        // if (res.list.length) {
        //   res.list.map((item) => {
        //     item.visible = item.visibleScope === 2
        //     item._checked = that.feeitemIds.includes(item.feeItemId)
        //     return item
        //   })
        //   that.currentPageAllItemIds = res.list.map(item => {
        //     return item.feeItemId
        //   })
        // }

        that.list = res.list
        that.total = res.totalRow
        that.loading = false

        // 如果当前是最后一页，同时没有数据，那么重新加载上一页
        if (res.totalRow > 0 && res.list.length === 0) {
          that.query.pageNumber -= 1
          that.loadPage()
        }
      })
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          }
          return
        }
        if (key === 'amount') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.amount)
          }
        } else if (key === 'usePrice') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.usePrice)
          }
        } else if (key === 'settleAmount') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.settleAmount)
          }
        } else if (key === 'confirmAmount') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.confirmAmount)
          }
        } else if (key === 'servicePrice') {
          sums[key] = {
            key,
            value: toMoney(this.summaryData.servicePrice)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })
      return sums
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.loadPage()
    },
    handlePageSizeChanged (pagesize) {
      this.query.pageSize = pagesize
      this.query.pageNumber = 1
      this.loadPage()
    },
    choiceDownLoadProgramme () {
      const that = this
      const params = {}
      if (parseInt(that.downLoadProgramme) === 1) {
        params.companyId = that.transactionReconciliationQuery.companyId
        params.startDate = that.transactionReconciliationQuery.startDate
        params.endDate = that.transactionReconciliationQuery.endDate
        params.queryString = that.transactionReconciliationQuery.queryString

        params.publisherId = this.query.publisherId
      } else {
        if (!that.feeitemIds.length) {
          that.$Notice.info({ desc: '请选择要下载的条目！' })
          return
        } else {
          params.feeItemIds = that.feeitemIds.join(',')
          params.startDate = that.transactionReconciliationQuery.startDate
          params.endDate = that.transactionReconciliationQuery.endDate
          // // 验证结束时间是否有值，无值则给默认值（上个月）
          // const prevMonth = GetPrevMonth(new Date())
          // if (that.transactionReconciliationQuery.endDate && (new Date(that.transactionReconciliationQuery.endDate) <= new Date(prevMonth))) {
          //   params.endDate = that.transactionReconciliationQuery.endDate
          // } else {
          //   params.endDate = prevMonth
          // }
        }
      }

      that.downLoadExecl(params)
    },
    downLoadExecl (params) {
      let dateStr = ParseDate(new Date())
      // 全部下载
      if (parseInt(this.downLoadProgramme) === 1) {
        if (params.startDate && params.endDate) {
          dateStr = ParseDateForMonth(params.startDate) + '至' + ParseDateForMonth(params.endDate)
        }
      }

      const filename = '交易账单明细(' + dateStr + ').xls'
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v2/bill/exportContractBillInfo', params, filename)
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.billDetails.beginUpdate
    },
    reloadList () {
      return this.$store.state.billDetails.reloadList
    },
    transactionReconciliationQuery () {
      return this.$store.state.billDetails.transactionReconciliationQuery
    }
  },
  watch: {
    beginUpdate () {
      this.query.companyId = this.transactionReconciliationQuery.companyId

      this.query.startDate = this.transactionReconciliationQuery.startDate
      this.query.endDate = this.transactionReconciliationQuery.endDate
      this.query.queryString = this.transactionReconciliationQuery.queryString

      this.query.pageNumber = 1
      this.loadSummaryData()
    },
    reloadList () {
      this.loadPage()
    }
  }
}
</script>
