<template>
  <div>
    <div v-if="invalidInfo.length">
      <Alert v-for="(invalidBean,index) in invalidInfo" :key="index" show-icon type="warning" class="m-b-10">
        <h4 class="text-orange">作废申请</h4>
        <div slot="desc">
          <Row>
            <i-col span="8">状态：
              <Tag color="blue">{{invalidBean.invalidStatusName}}</Tag>
              <a class="m-l-5" @click="handleShowHistory(invalidBean)">查看审批记录</a>
            </i-col>
            <i-col span="8">创建时间： {{invalidBean.createTime}}</i-col>
            <i-col span="8">最后更新时间： {{invalidBean.invalidTime}}</i-col>
          </Row>
          <Row>
            <i-col span="8">最后操作人： {{invalidBean.invalidUserName}}</i-col>
            <i-col span="8">描述： {{invalidBean.invalidMark}}</i-col>
          </Row>
          <p v-if="invalidBean.fileList.length>0">
            作废附件清单:
            <i-table stripe  :columns="approvalFileColumn" :data="invalidBean.fileList" size="small"></i-table>
          </p>
        </div>
      </Alert>
    </div>
    <div v-else>
      <p class="text-center p-t-10" >
        当前无作废记录
      </p>
    </div>

      <Modal v-model="showHistory" :width="800" footer-hide>
        <history ref="ApprovalHistory" :relateId="relateId" :type="'cancel'"></history>
      </Modal>
  </div>
</template>

<script>
import History from '@/components/approval/History'
import { downloadFileRequest } from '@/utils/download'
import { getbusinessinvalidinfo } from '@/api/scp/scpbusiness'

export default {
  props: {
    relateId: {
      type: [String, Number],
      required: true
    },
    processType: {
      type: Number,
      required: true
    }
  },
  components: {
    History
  },
  data () {
    return {
      invalidInfo: [],
      approvalFileColumn: [
        {
          title: '文件缩略图',
          render: (h, data) => {
            if (data.row.mimeType.startsWith('image')) {
              return h('Icon', {
                props: { type: 'md-images', size: 28, color: '#e96900' },
                style: { 'margin-left': '8px' }
              })
            } else if (this.isMSWord(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/WinWordLogoSmall.scale-180.png'), height: '30px' } })
            } else if (this.isMSExcel(data.row)) {
              return h('img', { attrs: { src: require('@/assets/images/ExcelLogoSmall.scale-180.png'), height: '30px' } })
            }
          }
        },
        { title: '文件名称', key: 'fileName' },
        { title: '文件大小 (kb)', key: 'length' },
        {
          title: '下载',
          render: (h, params) => {
            return h('a',
              {
                style: { fontSize: '20px' },
                on: {
                  click: () => {
                    this.downloadFile(params.row)
                  }
                }
              },
              [h('Icon', { props: { type: 'md-cloud-download' } })]
            )
          }
        }
      ],
      showHistory: false
    }
  },
  created () {
    getbusinessinvalidinfo({ bid: this.relateId }).then(res => {
      this.invalidInfo = res.invalidHistoryList
    })
  },
  methods: {
    downloadFile (file) {
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contract/downloadcontractinvalidfile', { fileId: file.id }, file.fileName)
    },
    isMSWord (file) {
      const mimeTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']
      return mimeTypes.includes(file.mimeType)
    },
    isMSExcel (file) {
      const mimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      return mimeTypes.includes(file.mimeType)
    },
    handleShowHistory (invalidBean) {
      this.showHistory = true
      const cancelBean = {
        processtype: this.processType,
        relateId: this.relateId,
        dataId: invalidBean.invalidId,
        processtypeFeature: 1
      }
      this.$refs.ApprovalHistory.getProcessCancelDetail(cancelBean)
    }
  }
}
</script>
