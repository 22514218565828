<template>
  <Card v-if="value&&value.length>0">
    <p slot="title">总价变化</p>
    <Row
      type="flex"
      align="middle"
      justify="center"
      :gutter="18"
    >
      <i-col span="11">
        <span class="money text-center">{{formatMoney(from[0])}}</span>
      </i-col>

      <i-col span="2" class="text-center">
        <Icon type="md-arrow-round-forward" size="40" color="green"></Icon>
      </i-col>

      <i-col span="11">
        <span class="money text-center">{{formatMoney(to[0])}}</span>
      </i-col>
    </Row>
  </Card>
</template>

<script>
// import orderExpand from './OrderExpand'
import { toMoney } from '@/utils/wnumb_own'
export default {
  // components: { orderExpand },
  props: {
    value: Array
  },
  data () {
    return {
      // columns: [
      //   {type: 'expand',
      //     width: 50,
      //     render: (h, data) => {
      //       return h(orderExpand, {
      //         props: {
      //           products: data.row.checkContractProductBeanList
      //         }
      //       })
      //     } },
      //     {title: '客户', key: 'advertiserName'},
      //     {title: '品牌', key: 'brandName'},
      //     {title: '开始档期', key: 'startDate'},
      //     {title: '结束档期', key: 'endDate'}
      //     // {title: '天数', key: ''},
      //     // {title: '总价', key: ''}
      //   // {title: '',
      //   //   render: (h, data) => {
      //   //     return h('a', {

      //   //     }, '查看详情')
      //   //   }}
      // ],
      from: [],
      to: []
    }
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    }

  },
  created () {
    this.value.forEach(order => {
      this.to = this.to.concat(order.to)
      this.from = this.from.concat(order.from)
    })
  }

}
</script>
